@import "../Constants";

.job-detail-modal {
  width: 60%;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  background: #ffffff;
}

.hidden {
  display: none;
}

.modal-container {
  top: 0;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(#00000070, #00000070);
  position: fixed;
}

.modal-main {
  border: solid 10px $fighting-fish-light-gray;
}

.modalHeader {
  color: #ffffff;
}

@media screen and (max-width: 700px) {
  .job-detail-modal {
    overflow: scroll;
    width: 95vw;
    height: 90vh;
  }
}

@media screen and (max-width: 600px) {
  .job-detail-modal {
    overflow: scroll;
    width: 95vw;
    height: 90vh;
  }
}
