@import "../Constants";

.modal-nav {
  height: 50px;
  border-bottom: $fighting-fish-light-gray solid 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  p {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
  }
  img {
    height: 20px;
    &:hover {
      cursor: pointer;
    }
  }
}