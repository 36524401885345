.body {
  font-size: 10px;
}

header {
  background-image: linear-gradient(#00000070, #00000070), url("../src/Header/happy-workers.jpg");
  background-size: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-teal {
  background-color: #17a2b8;
}
.body {
  font-size: 10px;
}